import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityHomePageConnection } from "@graphql-types";
import { Components } from "@components";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { ProviderContainer } from "@global";

interface Data extends Query {
  altRegions: SanityHomePageConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function IndexPage({ data, pageContext }: Props) {
  const { nodes } = data.allSanityHomePage;

  const {
    components,
    hero,
    seo,
  } = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);

  return (
    <>
      <SEO seoData={seo} alternateRegions={alternateRegions} heroData={hero} slug={pageContext?.pagePath} />
      <ProviderContainer>
        <Hero heroData={hero} isFullscreen />
        {components &&
          <Container flexDirection="column">
            {components.map(component => {
              if (component == null) return null;
              return (
                <Components key={component._key} data={component} />
              );
            })
            }
          </Container>
        }
      </ProviderContainer>
    </>
  );
}

export const query = graphql`
  query HomeQuery($region: String) {
    allSanityHomePage(filter: { region: { iban: { eq: $region } } }) {
      nodes {
        title
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        components {
          ... on SanityCalculatorBlock {
            ...sanityCalculatorBlock
          }
          ... on SanityFeaturedBlogsBlock {
            ...sanityFeaturedBlogsBlock
          }
          ... on SanityFullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          ... on SanityInfoBlock {
            ...sanityInfoBlock
          }
          ... on SanityMapBlock {
            ...sanityMapBlock
          }
          ... on SanityStatsBlock {
            ...sanityStatsBlock
          }
        }
      }
    }
    altRegions: allSanityHomePage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
