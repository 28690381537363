import React, { ReactNode, RefObject, useState } from "react";
import styled from "styled-components";

import { BlocksContent, Button, Indicator, VideoPopUp } from "@global";
import { Container, TextButton, LinearGradient } from "@util/standard";
import { MOBILE_BREAKPOINT, colors, TABLET_BREAKPOINT } from "@util/constants";
import {
  SanityHero,
  Maybe,
  SanityVideo,
  SanityImageWithMetaOrVideo,
  SanityImageOrImageWithMetaOrVideo,
  SanityImageWithMeta,
} from "@graphql-types";
import { FullHeightContainer, ParallaxContainer } from "@shared/shared.styles";
import MediaContent from "@shared/mediaContent";
import { isSanityVideo, isSanityImage, ButtonTheme } from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";
import { useStore } from "@state/store";
import { PlayIcon } from "@util/svg";
import { Parallax } from 'react-scroll-parallax';

const HeroContentContainer = styled(Container) <{
  hasProductImage: boolean | undefined | null;
  isSmallerHeader: boolean | undefined | null;
  hideExplore: boolean;
}>`
  max-width: 840px;
  position: absolute;
  flex-direction: column;
  bottom: 10%;
  left: 0;
  right: 0;
  width: 80%;
  margin: ${({ hideExplore }) => hideExplore ? 'auto auto 4% 10%' : 'auto auto 7% 10%'};
  z-index: 2;

  p {
    color: ${colors.white};
    max-width: 640px;
  }

  ${({ isSmallerHeader }) => isSmallerHeader && `
    h1:not(.h1, .h2, .h3, .h4){
      font-size: 80px;
      @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 45px;
      }
    }
  `}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ hasProductImage }) => hasProductImage && `top: 15%;`}
  }
`;

const BottomContainer = styled(Container)`
  position: absolute;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 75px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 75px;
  }
`;

const MediaContentStyled = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  > div:first-child{
    height: 100vh;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

interface Props {
  isFullscreen?: boolean;
  minHeight?: string;
  heroData: Maybe<SanityHero> | undefined;
  margin?: string;
}

interface HeroTemplateProps {
  isFullscreen?: boolean;
  minHeight?: string;
  children?: ReactNode;
  heroMedia: Maybe<Maybe<SanityImageOrImageWithMetaOrVideo>[]> | undefined | (Maybe<SanityImageWithMeta> | undefined)[];
  media: Maybe<Maybe<SanityImageOrImageWithMetaOrVideo>[]> | undefined | (Maybe<SanityImageWithMeta> | undefined)[];
  extraLargeHeader?: Maybe<boolean> | undefined;
  margin?: string;
  hideExplore?: boolean;
  refObj?: RefObject<HTMLDivElement>;
}

export const HeroTemplate = ({ isFullscreen = false, minHeight = '550px', children, heroMedia, media, extraLargeHeader, margin, hideExplore = false, refObj }: HeroTemplateProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { isMobileWidth } = useCheckScreenWidth();

  const { language } = useStore();

  return (
    <FullHeightContainer ref={refObj} margin={margin} height={isFullscreen ? "100vh" : "70vh"} minHeight={minHeight}>
      <ParallaxContainer>
        <Parallax disabled={isMobileWidth} y={isFullscreen ? ["-30%", "30%"] : ["-43%", "30%"]}>
          {heroMedia && heroMedia.map((selectedMedia) => {
            if (selectedMedia == null) {
              return null;
            }

            return (
              <React.Fragment key={selectedMedia._key}>
                {heroMedia && isSanityVideo(heroMedia[0] as SanityImageWithMetaOrVideo) ?
                  <>
                    <MediaContent data={selectedMedia} />
                    <LinearGradient />
                  </>
                  :
                  <MediaContentStyled>
                    <MediaContent data={selectedMedia} />
                    <LinearGradient />
                  </MediaContentStyled>
                }
              </React.Fragment>

            );
          })}
          {isMobileWidth ?
            <HeroContentContainer
              hasProductImage={
                heroMedia && heroMedia[0] && isSanityImage(heroMedia[0] as SanityImageWithMetaOrVideo)
              }
              hideExplore={hideExplore}
              isSmallerHeader={!extraLargeHeader}
            >
              {children}
            </HeroContentContainer>
            :
            <HeroContentContainer
              hasProductImage={
                heroMedia && heroMedia[0] && isSanityImage(heroMedia[0] as SanityImageWithMetaOrVideo)
              }
              hideExplore={hideExplore}
              isSmallerHeader={!extraLargeHeader}
              data-sal="slide-up"
              data-sal-duration="2000"
            >
              {children}
            </HeroContentContainer>
          }

          {(!hideExplore && isMobileWidth) ?
            <BottomContainer>
              <Indicator text={language?.heroExplore ?? "Explore"} />
              {media && media[0] && isSanityVideo(media[0] as SanityImageWithMetaOrVideo) && (
                <Container
                  margin="30px 0"
                  alignItems="center"
                  onClick={() => setModalVisible(true)}
                >
                  <TextButton underlined color="white">
                    {language?.heroWatchVideo ?? "Watch Video"}
                    <PlayIcon color="currentColor" margin="0 0 0 10px" />
                  </TextButton>
                </Container>
              )}
            </BottomContainer>
            : !hideExplore &&
            <BottomContainer
              data-sal="slide-down"
              data-sal-duration="2000"
            >
              <Indicator text={language?.heroExplore ?? "Explore"} />
              {media && media[0] && isSanityVideo(media[0] as SanityImageWithMetaOrVideo) && (
                <Container
                  margin="30px 0"
                  alignItems="center"
                  onClick={() => setModalVisible(true)}
                >
                  <TextButton underlined color="white">
                    {language?.heroWatchVideo ?? "Watch Video"}
                    <PlayIcon color="currentColor" margin="0 0 0 10px" />
                  </TextButton>
                </Container>
              )}
            </BottomContainer>
          }

          {media && media[0] && isSanityVideo(media[0] as SanityImageWithMetaOrVideo) && (
            <VideoPopUp
              videoUrl={(media[0] as SanityVideo).url as string}
              visible={modalVisible}
              setVisible={setModalVisible}
            />
          )}
        </Parallax>
      </ParallaxContainer>
    </FullHeightContainer>
  );
};

const Hero = ({ isFullscreen = false, minHeight = '550px', heroData, margin }: Props) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  if (heroData == null) {
    return null;
  }

  const { media, mediaMobile, blockContent, links, extraLargeHeader } = heroData;
  const heroMedia = ((isTabletWidth || isMobileWidth) && (mediaMobile && mediaMobile.length > 0)) ? mediaMobile : media;

  return (
    <HeroTemplate isFullscreen={isFullscreen} minHeight={minHeight} heroMedia={heroMedia} media={media} extraLargeHeader={extraLargeHeader} margin={margin}>
      <BlocksContent data={blockContent} />
      {links && (
        <Container margin="30px 0 0 0">
          {links.map((link, index) => {
            if (link == null) {
              return link;
            }
            const { url, linktext } = link;

            if (linktext == null) {
              return null;
            }

            return (
              <Button
                key={link._key}
                theme={link.buttonTheme as ButtonTheme}
                text={linktext as string}
                linkTo={url as string}
                margin="0 15px 0 0"
              />
            );
          })}
        </Container>
      )}
    </HeroTemplate>
  );
};

export default Hero;
